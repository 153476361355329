import * as core from '@mt-webpages/core'
import * as recaptchaClientService from 'src/data/recaptchaClientService'

export type RecaptchaClientContext = {
  grecaptcha: typeof grecaptcha
  timeoutMs: number
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
}

export const toRecaptchaClientService = (
  context: RecaptchaClientContext
): recaptchaClientService.RecaptchaClientService => {
  return {
    getToken: input => getToken(input)(context)
  }
}

export const getToken =
  (input: recaptchaClientService.GetTokenInput) =>
  (context: RecaptchaClientContext): Promise<string> => {
    return Promise.race([
      new Promise<string>((resolve, reject) => {
        context.grecaptcha.ready(async () => {
          try {
            const token = await context.grecaptcha.execute(input.siteKey, { action: input.action })
            resolve(token)
          } catch (error: unknown) {
            if (error instanceof Error) {
              context.logger({
                message: error.message,
                level: 'error'
              })
            } else {
              context.logger({
                message: 'Unknown error',
                level: 'error'
              })
            }
            reject(error)
          }
        })
      }),
      new Promise<string>((_, reject) => {
        setTimeout(() => {
          reject(new Error(`Did not complete within: ${context.timeoutMs}ms`))
        }, context.timeoutMs)
      })
    ])
  }
