import * as core from '@mt-webpages/core'
import * as recaptchaClientService from 'src/data/recaptchaClientService'
import * as sessionStoreService from 'src/data/sessionStoreService'
import * as mtWebpagesApiClientService from 'src/data/mtWebpagesApiClientService'
import * as mtWebpagesWebSessionService from 'src/data/mtWebpagesWebSessionService'

export type MtWebpagesWebSessionContext = {
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  window: globalThis.Window
  hubspotUtkSessionKey: string
  recaptchaClient: recaptchaClientService.RecaptchaClientService
  sessionStore: sessionStoreService.SessionStoreService
  mtWebpagesApiClient: mtWebpagesApiClientService.MtWebpagesApiClientService
}

export const toMtWebpagesWebSessionService = (
  context: MtWebpagesWebSessionContext
): mtWebpagesWebSessionService.MtWebpagesWebSessionService => {
  return {
    submitHubspotForm: input => submitHubspotForm(input)(context),
    getHubspotContact: properties => getHubspotContact(properties)(context),
    setHubspotContact: properties => setHubspotContact(properties)(context)
  }
}

export const submitHubspotForm =
  (input: mtWebpagesWebSessionService.SubmitHubspotFormInput) =>
  async (context: MtWebpagesWebSessionContext): Promise<void> => {
    const recaptchaToken = await context.recaptchaClient.getToken({
      action: 'submitHubspotForm',
      siteKey: input.recaptchaSiteKey
    })
    await context.mtWebpagesApiClient.postHubspotForm({
      ...input,
      submittedAt: Date.now(),
      context: {
        hutk: getHubspotUtk(context) || undefined,
        pageName: context.window.name,
        pageUri: context.window.location.href
      },
      recaptchaToken
    })
    await setHubspotContact({ properties: input.fields })(context)
  }

export const getHubspotContact =
  (properties: string[]) =>
  async (context: MtWebpagesWebSessionContext): Promise<core.data.hubspotContact.HubspotContact> => {
    try {
      const token = getHubspotUtk(context)
      return token
        ? (await context.mtWebpagesApiClient.getSessionHubspotContact({ token, properties })).contact
        : { properties: {} }
    } catch (error) {
      context.logger({
        message: 'Failed to fetch hubspot contact',
        level: 'warning',
        context: { error }
      })
      return { properties: {} }
    }
  }

export const setHubspotContact =
  (contact: core.data.hubspotContact.HubspotContact) => async (context: MtWebpagesWebSessionContext) => {
    try {
      const token = getHubspotUtk(context)
      if (token) {
        await context.mtWebpagesApiClient.setSessionHubspotContact({ token, contact })
      }
    } catch (error) {
      context.logger({
        message: 'Failed to fetch hubspot contact',
        level: 'warning',
        context: { error }
      })
    }
  }

export const getHubspotUtk = (context: MtWebpagesWebSessionContext) => {
  return context.sessionStore.get(context.hubspotUtkSessionKey)
}
