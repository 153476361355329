import * as rxjs from 'rxjs'
import * as core from '@mt-webpages/core'
import * as locale from 'src/data/locale'
import * as subscription from 'src/data/subscription'
import * as mtWebpagesWebSessionService from 'src/data/mtWebpagesWebSessionService'
import * as hubspotFormEvent from 'src/data/hubspotFormEvent'
import * as hubspotSubmitFormErrorDetail from 'src/data/hubspotSubmitFormErrorDetail'
import * as hubspotFormState from 'src/data/hubspotFormState'
import * as hubspotFormElement from 'src/data/hubspotFormElement'
import * as hubspotFormFieldElement from 'src/data/hubspotFormFieldElement'
import * as hubspotFormSubmitFieldErrorTranslation from 'src/data/hubspotFormSubmitFieldErrorTranslation'

export type HubspotFormContext = {
  window: Window
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  locale: locale.Locale
  defaultObjectTypeId: core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId
  formId: string
  formElement: hubspotFormElement.HubspotFormElement
  formSuccessElementQuery: string
  formFailureElementQuery: string
  fieldErrorTranslation: hubspotFormSubmitFieldErrorTranslation.HubspotFormSubmitFieldErrorTranslation
  formFieldErrorDatasetIdentifier: string
  formInvalidFieldCssClassname: string
  formfieldErrorListContainerCssClassname: string
  formfieldErrorListCssClassname: string
  formfieldErrorListItemCssClassname: string
  formSubmitLoadingClassname: string
  formTriggerFieldValueDelimiter: string
  mtWebpagesWebSession: mtWebpagesWebSessionService.MtWebpagesWebSessionService
  onFormEvent: (event: hubspotFormEvent.HubspotFormEvent) => void
  recaptchaSiteKey: string
}

export const addFormEventListeners = (context: HubspotFormContext): subscription.Subscription => {
  const { formElement: form, formId } = context
  const initialFormState = hubspotFormState.fromForm(form)
  const formFields = hubspotFormFieldElement.fromForm(form)
  const formEventChannel = new rxjs.BehaviorSubject<hubspotFormEvent.HubspotFormEvent>(hubspotFormEvent.noop)
  const formEvent$ = rxjs
    .merge(
      formEventChannel,
      rxjs
        .from(context.mtWebpagesWebSession.getHubspotContact(formFields.map(field => field.name)))
        .pipe(
          rxjs.switchMap(contact => rxjs.from(Object.entries(contact.properties))),
          rxjs.map(([name, property]) =>
            hubspotFormEvent.setFieldValue({
              form,
              formId,
              name,
              value: `${property.value}`
            })
          )
        )
        .pipe(rxjs.catchError(() => rxjs.of(hubspotFormEvent.noop))),
      ...formFields.map(field =>
        rxjs.fromEvent(field, 'change').pipe(
          rxjs.map(() =>
            hubspotFormEvent.fieldDomChange({
              form,
              formId,
              metadata: hubspotFormFieldElement.toHubspotFormFieldMetadata(field)
            })
          )
        )
      )
    )
    .pipe(
      rxjs.tap(event => {
        try {
          context.onFormEvent(event)
        } catch (error) {
          context.logger({
            message: 'Error from onFormEvent',
            level: 'warning',
            context: { error }
          })
        }
      })
    )
  const formState$ = formEvent$.pipe(
    rxjs.scan((formState, event) => hubspotFormState.fromEvent(event, formState), initialFormState),
    rxjs.distinctUntilChanged()
  )
  return rxjs
    .fromEvent(form, 'submit')
    .pipe(
      rxjs.withLatestFrom(
        formState$.pipe(
          rxjs.tap(formState => {
            hubspotFormState.updateDom(initialFormState, formState)(context)
          })
        )
      ),
      rxjs.filter(([_, formState]) => formState.submission.status !== 'loading'),
      rxjs.mergeMap(([event, formState]) =>
        rxjs.from(
          (async () => {
            event.stopPropagation()
            event.preventDefault()
            const input = hubspotFormState.toMtWebpagesHubspotFormApiSubmitInput(formState)(context)
            formEventChannel.next(
              hubspotFormEvent.formSubmitStart({
                form,
                formId
              })
            )
            await context.mtWebpagesWebSession.submitHubspotForm({
              formId: context.formId,
              fields: core.lib.record.map(
                field => ({
                  ...field,
                  objectTypeId: field.objectTypeId || context.defaultObjectTypeId
                }),
                input
              ),
              recaptchaSiteKey: context.recaptchaSiteKey
            })
            formEventChannel.next(
              hubspotFormEvent.formSubmitSuccess({
                form,
                formId,
                formInput: core.lib.record.map(field => field.value, input)
              })
            )
          })()
        )
      ),
      rxjs.catchError((unknownError: unknown) => {
        return rxjs.of(
          hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail.match(
            errorDetail => {
              formEventChannel.next(
                hubspotFormEvent.formSubmitFailure({
                  form,
                  formId,
                  errorDetail
                })
              )
            },
            errorDetail => {
              if (errorDetail.formErrors.length) {
                formEventChannel.next(
                  hubspotFormEvent.formSubmitFailure({
                    form,
                    formId,
                    errorDetail
                  })
                )
              }
            }
          )(hubspotSubmitFormErrorDetail.fromUnknownError(unknownError))
        )
      }),
      rxjs.tap(() => {
        formEventChannel.next(
          hubspotFormEvent.formSubmitComplete({
            form,
            formId
          })
        )
      })
    )
    .subscribe().unsubscribe
}
